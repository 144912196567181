@font-face {
  font-family: 'Verifont';
  src:
    local('Verifont'),
    local('Verifont'),
    url(./font/veri.otf) format('opentype');
}
body {
  background-color:#c95f2c!important;
}
.App {
  text-align: center;
  font-family: 'Verifont';
  background-color: whitesmoke!important;
}
.Nav {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: #c95f2c;
  color: white;
}
.nav > h1{
  color: white
}
H1 {
  font-family: 'Verifont';
}
h2 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.2vmin!important;
}
h3 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.1vmin!important;
}
h4 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.9vmin!important;

}
P {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.8vmin!important;

}
input {
  width: 50%;
}
.logout {
  border: none;
  background-color: transparent;
}
table {
  display: flex;
  flex-direction: column;
  width: 95%;
}
tr {
  display: flex;
  padding: 0.5rem 0 ; 
  justify-content: space-between;
  flex-direction:row;
}
.desc {
  width: 25%;
  margin: 0 2%;
  padding: 4% 0;
}
.trdesk {
  display: flex;
  /* padding: 0.5rem 0 ;  */
  justify-content: flex-start;
  flex-direction:row;
  border-bottom:none;
  font-weight: bold;
}
.tdprimarydesk {
  border: 2px solid #e47830;
  border-radius: 15px; 
  background-color: #e47830;
  color: white;
  width: 25%;
  margin: 0 2%;
  padding: 4% 0;
}
.tdprimarymov {
  display: none;
}
.min {
  display: inline-table;
  width: 5%!important;
}
.title {
  width: 35%;
}
.titlemov {
  width: 35%;
}
.title > tr {
  border: none;
}
.titlemov > tr {
  border: none;
}
.title td{
  display:flex;
  flex-direction: row;
  justify-content: space-around;
  width:100%;
  font-family: Arial, Helvetica, sans-serif;
}
.titlemov td {
  display:flex;
  flex-direction: row;
  justify-content: space-around;
  width:100%;
  font-family: Arial, Helvetica, sans-serif;
}
th {
  align-self: center;
  width: 20%;
}
.butn {
  width: max-content;
  align-self: center;
  margin: 1rem 0;
}
.trContainerBorder {
  width: 70%;
  display: flex;
  flex-direction: row;
}
.trContainerBorder td{
  width: 50%;
  padding: 5% 0 ;
  display: flex; 
  flex-direction: row;
  justify-content: space-around;
  border: 2px solid #e47830;
  border-radius: 15px; 
}
td > input {
  font-size: 1rem;
}
.logcontainer {
  /* background: rgb(243, 222, 205); */
  /* background: linear-gradient(#e08548 0%, #e47830 100%);   */
  height: 1080px;
  /* background: #e47830; */
  background-color: white;
  padding: 10px 0;
}
.center-log {
  /* color: white; */
  font-size: 500%!important;
  text-align: center;
}
.Form {
  align-content: center;
}
.App-logo {
  /* height: 40vmin; */
  width: 5rem;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  /* background-color: #282c34; */
  background: whitesmoke;
  /* background: linear-gradient(rgb(169, 74, 36) 0%, rgba(135, 0, 0, 0.8) 100%); */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  margin: 5% 0;
  /* color: white; */
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.calendar {
  width: 65%;
  text-align: center;
  background-color: transparent;
  border: none;
  font-size: 1.8vmin;
  border-bottom: 2px solid white;
  color:white;
}
.calendar::placeholder {
  text-align: center;
  color: white;
}
.sign {
  text-align: -webkit-center;
  /* color: white; */
  font-family: 'Verifont';
}
.sign input {
  background-color: transparent;
  border:none;
  /* color: white; */
}
.btn-signup {
  text-align: center!important;
}
.line {
  border-bottom: 1px solid black;
  width: 15%;
}
.input {
  width: 10%!important;
}


/*PLAYER*/

.tdprimary {
  border: 2px solid #e47830;
  border-radius: 15px; 
  background-color: #e47830;
  color: white;
  width: 21%;
  margin: 0 2%;
  padding: 5% 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.tdContainerBorder td{
  width: 33%!important;
  /*padding: 5% 0 ; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.tdContainerBorder h3 {
  padding: 5% 0 5% 0 ;
  font-weight:normal!important;
  font-size: 1.4vmin!important;
  display: inline-block;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  white-space: nowrap; 
}
.tdContainer {
  width: 74%;
  display: flex;
  justify-content: space-around;
  font-size: 2.3vmin;
  /* overflow: auto; */
  /* margin-left: 2.5%; */
}
.tdContainerBorder {
  width: 72%;
  display: flex;
  justify-content: space-around;
  border: 2px solid #e47830;
  border-radius: 15px; 
  /* margin-left: 10%; */
  margin-left: 2.5%;
  /* padding: 5% 0; */
}
.descpl {
  width: 25%;
  margin: 0 auto 0 0;
  font-size: 3vmin;
}

.trplayer {
  display: flex;
  /* padding: 0.5rem 0 ;  */
  justify-content: flex-start;
  flex-direction:row;
  border-bottom:none;
  font-weight: bold;
}

/*SALES*/

.AppPL {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif!important;
  background-color:#c95f2c!important;
}

.AppPL p {
  font-family: Arial, Helvetica, sans-serif!important;

}
.AppPL h3 {
  font-family: Arial, Helvetica, sans-serif!important;
  font-weight: bold;
}
.App-header-pl {
  /* height: 1920px; */
  background-color:white;
  align-items: center;
  font-size: calc(10px + 2vmin);
}
.dash{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5%;
}
.dash h5{
  font-family: 'Verifont';
  font-size: 2vmin!important;
}
.NavPL {
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem 0 2rem;
  background: #c95f2c;
  color: white;
  align-items: center;
}
.PL{
  margin: -2% 2% 0 0;
}
.tdprimaryS {
  border: 2px solid #e47830;
  border-radius: 15px; 
  background-color: #e47830;
  color: white;
  width: 15%;
  margin: 0 2%;
  padding: 5% 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

}
.tdContainerBorderS td{
  width: 33%!important;
  /*padding: 5% 0 ; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-right: 1px solid black;
  width: 33%!important;
  border-right: 1px solid black;

}

.tdContainerBorderS h3 {
  padding: 5% 0 5% 0 ;
  font-weight:normal!important;
  font-size: 1.4vmin!important;
  display: inline-block;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  white-space: nowrap; 
}
.tdContainerS {
  width: 85%;
  display: flex;
  justify-content: space-around;
  font-size: 1.4vmin;
  margin: 0 auto;
  
  /* overflow: auto; */
  /* margin-left: 2.5%; */
}
.tdContainerS th{
  width: 33%!important;
  border-right: 1px solid black;
}

.tdContainerS td{
  border-bottom: 1px solid black;
  min-width: max-content;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 100px;
}
.tdContainerS div{
  border-bottom: 1px solid black;
  min-width: max-content;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* min-height: 100px; */
  min-height: 70px;
  height: 80px;
}

.tdContainerS h3{
  margin: 0 1px;
  padding: 5% 5% 5% 5% ;
  font-weight:normal;
  font-size: 1.6vmin!important;
  display: inline-block;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  white-space: nowrap; 
}
.tdContainerS h2{
  font-weight:bold;
  font-size: 1.6vmin!important;
  margin: auto 0;
}
.tdContainerBorderS {
  width: 75%;
  display: flex;
  justify-content: space-around;
  border: 2px solid #e47830;
  border-radius: 15px; 
  /* margin-left: 10%; */
  margin-left: 2.5%;
  
  /* padding: 5% 0; */
  
}
.descplST {
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 80px;
}
.descplST p{
  font-size: 1.8vmin!important;
  margin: auto 0;
}
.sup div h2 {
  font-size: 3.0vmin!important;
}
.descplS {
  /*fix menú*/
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
  background: white;
  border-bottom: 1px solid black;
  /* min-height: 100px; */
  min-height: 70px;
  height: 80px;
}

.descplS p {
  font-size: 1.2vmin!important;
  margin: 0 auto;
}
.descplS h3 {
  font-size: 1.4vmin!important;
  margin: 0 auto;
}
.trplayerSH {
  display: flex;
  /* margin: 0.5rem 0 ;  */
  justify-content: flex-start;
  flex-direction:row;
  border-bottom:none;
  font-weight: bold;
  color: white;
  background: #5F2412;
  padding: 0;
}
.sup div{
  border: none;
}
.tHead {
  margin: '0 0 0 0';
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 3;
}

.trplayerS {
  padding: 0;
  display: flex;
  /* padding: 0.5rem 0 ;  */
  justify-content: flex-start;
  flex-direction:row;
  border-bottom:none;
  font-weight: bold;
}
.react-datepicker-popper{
  z-index: 99;
}

.tableMov {
  width: 100%;
}
.NavPL div p{
  font-size: 2.8vmin!important;
}
.cal {
  display: flex;
  flex-direction: column;
  border:1px solid black;
  border-radius: 15px;
  justify-content: space-around;
  width: 50%;
  height: 115px;
  padding-top: 1%;
}
.cal2 {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 1%;
  /* height: 65px; */
  /* margin: 0 0 10px 0; */
}
/* .tableMov {
  width: max-content;
} */

 /*LOADING*/
 .loadMov{
  width: auto!important;
}
.load{
  font-size: 2.5vmin!important;
}
.loadDot{
  width: 5px!important;
  height: 5px!important;
}

.hora {
  color: white;
  font-size: 1.6vmin;
  /* margin: -10px 0 5px 0; */
  background-color: #c95f2c;
}
/* #gauge-chart2 {
  width: 25%!important;
} */
.up {
  margin: 0 0 0 23px;
  color: #fff;
  /* text-decoration: none; */
  /* background-color: #5F2412; */
  /* font-family: 'Helvetica';
  font-weight: 700;
  font-size: 3em; */
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 5px 0px #5F2412;
  -moz-box-shadow:0px 5px 0px #5F2412;
  box-shadow: 0px 5px 0px #5F2412;
  /* margin: 100px auto;
  width: 160px; */
  
  text-align: center;
  -webkit-transition: all .1s ease;
  -moz-transition: all .1s ease;
  -ms-transition: all .1s ease;
  -o-transition: all .1s ease;
  transition: all .1s ease;
  
}

.up:active {
  -webkit-box-shadow: 0px 2px 0px #5F2412;
  -moz-box-shadow: 0px 2px 0px #5F2412;
  box-shadow: 0px 2px 0px #5F2412;
  
}
.upcon {
  display: block;
  width: 1rem;
  height: 1.5rem;
  margin: 0 auto;
}
.loadup{
  margin: 0 0 0 23px;
  color: white;
}
.hourBar {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  background: #c95f2c;
  padding: 0 0 1rem 0;
}
.activeHour {
  padding: 10px 0;
  background: orange;
}


/*SEARCH*/

.search {
  width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 2%;
 
}

.searchTerm {
  width: 100%;
  border: 3px solid #5f2412;
  /* border-right: none; */
  padding: 5px;
  height: 36px;
  border-radius: 5px;
  outline: none;
  color: #9DBFAF;
}

.searchTerm::placeholder {
  font-weight: bold;
  text-align-last: center;
  color: #5f2412;
  font-size: 1.5vmax;
}
.searchTerm:focus{
  color: #5f2412;
}

.searchButton {
  width: 40px;
  height: 36px;
  border: 1px solid #5f2412;
  background: #5f2412;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}

/*Resize the wrap to see the search bar change!*/
.wrap{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
}
/* table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.styled-select.slate {
  height: 34px;
  width: 240px;
} */

.progress {
  width: 80%;
  margin: 0 auto;
}



/*TABLET*/

@media only screen and (max-width: 800px){
  .body {
    min-width: min-content;
  }
  .input {
    width: 20%!important;
  }
  .trContainerBorder {
    width: 70%;
    display: flex;
    flex-direction: column;
  }
  .trContainerBorder td{
    width: 100%;
    padding: 5% 0 ;
    display: flex; 
    flex-direction: row;
    justify-content: space-around;
    border: 2px solid #e47830;
    border-radius: 15px; 
  }
  .titlemov {
    display: none;
  }
  .title {
    width: 63%;
  }
  .title > h3 {
    display: none;
  }
  .tdprimarymov {
    display: block;
    width: 10%;
  }
  .tdprimarymov > p {
    display: flex; 
    flex-direction: row;
    margin: 60% 0;
  }
  .App-header-pl {
    position: relative;
    z-index: 0;
    /* top:200px; */
    top: 14rem;
  }
  .NavPL{
    padding: 1rem 0;
    align-items: center;
  }
  .NavPL h1{
    font-size: 2.4vmax;
    padding: 1% auto ;
  }
  .NavPL p{
    font-size: 1.2vmax!important;
    font-weight: bold;
    margin: 0 auto;
  }
  .NavPL h2{
    font-size: 0.8vmax!important;
    font-weight: bold;
  }
  .NavPL h3{
    font-size: 0.8vmax!important;
  }
  .PL{
    margin: 0 auto;
    width: 25%;
    align-self: center;
  }
  .cal h3 {
    font-size: 2vmin!important;
    width: 75%;
    margin: 0 auto;
  }
  .cal3 h3{
    font-size: 2vmin!important;
    width: 75%;
    margin: 0 auto;
  }
  .calMov{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .calendar {
    width: 80%;
    text-align: center;
    border-radius: 3px;
    font-size: 2.2vmin;
  }
  .trplayerSH{
    height: 50px;
  }
  .descplST {
    border: none;
  }
  .descplST p {
    margin: auto 0;
    font-size: medium!important;
  }
  .descplS {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-bottom: 1px solid black;
    min-height: 70px;
  }
  .descplS h3{
    font-size: small!important;
    margin: 0 auto;
  }
  .descplS p{
    font-size: xx-small!important;
    margin: 0 auto;
  }
  .tableMov {
    width: max-content;
  }
  .tdContainerS {
    width: auto;
    width: 100%;
    margin: 0 ;
 }
  .tdContainerS td{
    border-bottom: 1px solid black;
    min-width: max-content;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 80px;
  }
  .tdContainerS div{
    border-bottom: 1px solid black;
    min-width: auto;
    width: 60px;
    width: 90px;
    padding: 0 auto!important;
    margin: 0 auto!important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 60px;
  }
  .tdContainerS h3{
    display: flex!important;
    justify-content: center;
    flex-direction: column;
    font-weight:normal;
    font-size: 1.8vmin!important;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    height: 30px;
    margin: 0;
  }
  .trplayerS {
    display: flex;
    padding: 0;
    justify-content: flex-start;
    flex-direction:row;
    border-bottom:none;
    font-weight: bold;
  }
  .AppPL {
    margin: 0 auto;
  }
  .react-datepicker-popper{
    transform: translate3d(50%, 50%, 0px)!important;
    right: 50%!important;
  }
  .sup div {
    border: none;
    padding: 0 auto!important;
  }
  .log{
    width: 40%!important;
  }
  .line {
    border-bottom: 1px solid black;
    width: 40%;
  }
  .logimg{
    width: 60%!important;
  }
  .alert > h1 {
    font-size: 0.5vmin;
  }
  /*LOADING*/
  .loadMov{
    width: auto!important;
  }
  .load{
    font-size: 2.5vmin!important;
  }
  .loadDot{
    width: 5px!important;
    height: 5px!important;
  }
  .fixNav{
    display: block;
    position: fixed;
    z-index: 1;
    width: 100%;
  }
  .delNav {
    transition: all 0.5s;
    display: block;
    position: fixed;
    z-index: 0;
    width: 100%;
   
  }
  tbody {
    background-color: white;
  }
  .hora{
    font-size: 2.6vmin;
  }
  .hourBar {
    padding: 0;
  }
  .searchTerm::placeholder {
    font-size: 2vmax;
  }
}

/*MOVIL*/

/* @media (max-width: 768px) { */
@media only screen and (max-width: 480px){
  .body {
    min-width: min-content;
  }
  .input {
    width: 20%!important;
  }
  .trContainerBorder {
    width: 70%;
    display: flex;
    flex-direction: column;
  }
  .trContainerBorder td{
    width: 100%;
    padding: 5% 0 ;
    display: flex; 
    flex-direction: row;
    justify-content: space-around;
    border: 2px solid #e47830;
    border-radius: 15px; 
  }
  .titlemov {
    display: none;
  }
  .title {
    width: 63%;
  }
  .title > h3 {
    display: none;
  }
  .tdprimarymov {
    display: block;
    width: 10%;
  }
  .tdprimarymov > p {
    display: flex; 
    flex-direction: row;
    margin: 60% 0;
  }
  .App-header-pl {
    position: relative;
    z-index: 0;
    /* top:200px; */
    top: 14rem;
  }
  .NavPL{
    padding:3%;
    align-items: center;
  }
  .NavPL h1{
    font-size: 2.4vmax;
    padding: 1% auto ;
  }
  .NavPL p{
    font-size: 1.2vmax!important;
    font-weight: bold;
    margin: 0 auto;
  }
  .NavPL h2{
    font-size: 0.8vmax!important;
    font-weight: bold;
  }
  .NavPL h3{
    font-size: 0.8vmax!important;
  }
  .PL{
    margin: 0 auto;
    width: 25%;
    align-self: center;
  }
  .cal h3 {
    font-size: 2vmin!important;
    width: 75%;
    margin: 0 auto;
  }
  .cal3 h3{
    font-size: 2vmin!important;
    width: 75%;
    margin: 0 auto;
  }
  .calMov{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .calendar {
    width: 80%;
    text-align: center;
    border-radius: 3px;
    font-size: 2.2vmin;
  }
  .trplayerSH{
    height: 50px;
  }
  .descplST {
    border: none;
    width: 7%;
    
  }
  .descplST p {
    margin: auto 0;
    font-size: medium!important;
  }
  .descplS {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-bottom: 1px solid black;
    min-height: 80px;
    width: 7%;
    /* min-height: 50px;
    height: 55px; */

  }
  .descplS h3{
    font-size: small!important;
    margin: 0 auto;
  }
  .descplS p{
    font-size: x-small!important;
    margin: 0 auto;
  }
  .tableMov {
    width: max-content;
  }
  .tdContainerS {
    width: auto;
    width: 100%;
    margin: 0 ;
   
  }
  .tdContainerS td{
    border-bottom: 1px solid black;
    min-width: max-content;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 80px;
  }
  .tdContainerS div{
    border-bottom: 1px solid black;
    min-width: auto;
    width: 60px;
    width: 90px;
    padding: 0 auto!important;
    margin: 0 auto!important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 80px;
    /* min-height: 50px;
    height: 55px; */
 
  }
 .tdContainerS h3{
    display: flex!important;
    justify-content: center;
    flex-direction: column;
    font-weight:normal;
    font-size: 3.8vmin!important;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    height: 30px;
    margin: 0;
  }
  .trplayerS {
    display: flex;
    padding: 0;
    justify-content: flex-start;
    flex-direction:row;
    border-bottom:none;
    font-weight: bold;
  }
  .AppPL {
    margin: 0 auto;
  }
  .react-datepicker-popper{
    transform: translate3d(50%, 50%, 0px)!important;
    right: 50%!important;
  }
  .sup div {
    border: none;
    padding: 0 auto!important;
  }
  .log{
    width: 40%!important;
  }
  .line {
    border-bottom: 1px solid black;
    width: 40%;
  }
  .logimg{
    width: 60%!important;
  }
  .alert > h1 {
    font-size: 0.5vmin;
  }
  /*LOADING*/
  .loadMov{
    width: auto!important;
  }
  .load{
    font-size: 2.5vmin!important;
  }
  .loadDot{
    width: 5px!important;
    height: 5px!important;
  }
  .fixNav{
    display: block;
    position: fixed;
    z-index: 1;
    width: 100%;
  }
  .delNav {
    transition: all 0.5s;
    display: block;
    position: fixed;
    z-index: 0;
    width: 100%;
  }
  tbody {
    background-color: white;
  }
  .hora{
    font-size: 3.6vmin;
  }
  .hourBar {
    padding: 0;
  }
  .dash{
    display: flex;
    flex-direction: column;
  }
  .dash h5{
    font-family: 'Verifont';
    font-size: 3.8vmin!important;
  }
  .activeHour{
    padding: 8px 0;
  }
  .searchTerm::placeholder {
    font-size: 2vmax;
  }
}



